<template>
  <div class="integral-page">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading"
                :finished="finished"
                :loading-text="loadingText"
                finished-text="没有更多了"
                @load="getDataList">
                <div v-for="(item, index) in integralList" :key="index" class="integral-item">
                    <div class="integral-item-top">
                        <div class="integral-item-topAuto">{{ item.time }}</div>
                    </div>
                    <ul class="integral-item-bottom">
                        <li v-for="(v, i) in item.children" :key="i" class="integral-item-li">
                            <div class="integral-item-liLeft">
                                <img :src="v.img" />
                            </div>
                            <div class="integral-item-liRight">
                                <div class="integral-liRight-top">
                                    <span>{{ v.name }}</span>
                                    <span>{{ v.individual }}</span>
                                </div>
                                <div class="integral-liRight-center">
                                    <span>{{ v.time }}</span>
                                </div>
                                <div class="integral-liRight-bottom">
                                    <div class="integral-liRight-bottomL">
                                        {{ v.label }}
                                    </div>
                                    <div class="integral-liRight-bottomR">
                                        <div>{{ v.Integral }}</div>
                                        <div>积分</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
        </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { List, PullRefresh } from "vant";
export default {
  name: 'IntegralDetails',
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  data() {
    return {
        refreshing: false,
        loading: false,
        loadingText: "加载中..",
        finished: false,
        integralList: [
            {
                time: '2020年2月',
                children: [
                    {
                        img: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
                        name: '腾讯视频vip会员',
                        individual: 1,
                        time: '2021.04.12 19:44:00',
                        label: '卡密商品',
                        Integral: 6000
                    },
                    {
                        img: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
                        name: '腾讯视频vip会员',
                        individual: 1,
                        time: '2021.04.12 19:44:00',
                        label: '卡密商品',
                        Integral: 6000
                    },
                    {
                        img: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
                        name: '腾讯视频vip会员',
                        individual: 1,
                        time: '2021.04.12 19:44:00',
                        label: '卡密商品',
                        Integral: 6000
                    }
                ]
            },
            {
                time: '2020年1月',
                children: [
                    {
                        img: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
                        name: '腾讯视频vip会员',
                        individual: 1,
                        time: '2021.04.12 19:44:00',
                        label: '卡密商品',
                        Integral: 6000
                    },
                    {
                        img: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
                        name: '腾讯视频vip会员',
                        individual: 1,
                        time: '2021.04.12 19:44:00',
                        label: '卡密商品',
                        Integral: 6000
                    },
                    {
                        img: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
                        name: '腾讯视频vip会员',
                        individual: 1,
                        time: '2021.04.12 19:44:00',
                        label: '卡密商品',
                        Integral: 6000
                    },
                    {
                        img: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
                        name: '腾讯视频vip会员',
                        individual: 1,
                        time: '2021.04.12 19:44:00',
                        label: '卡密商品',
                        Integral: 6000
                    },
                    {
                        img: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
                        name: '腾讯视频vip会员',
                        individual: 1,
                        time: '2021.04.12 19:44:00',
                        label: '卡密商品',
                        Integral: 6000
                    }
                ]
            }
        ]
    }
  },
  created() {
    document.title = '积分明细'
  },
  methods: {
    // 获取订单列表
    getDataList() {
        console.log("上拉刷新");
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
            this.finished = true;
        }, 2000);
    },
    // 下拉刷新
    onRefresh() {
        setTimeout(() => {
            this.refreshing = false;
        }, 2000);
    },
  },
}
</script>

<style lang="less" scoped>
.integral-page {
    flex: 1;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 0.08rem;


    .integral-item-top {
        width: 7.5rem;
        height: 0.64rem;
        background: #F6F7F7;
        line-height: 0.64rem;
        .integral-item-topAuto {
            font-size: 0.24rem;
            font-weight: 400;
            color: #666666;
            margin-left: 0.4rem;
        }
    }

    .integral-item-bottom {
        background: #FFFFFF;
        
        .integral-item-li {
            width: 7.5rem;
            height: 1.9rem;
            background: #FFFFFF;
            display: flex;

            .integral-item-liLeft {
                width: 2.64rem;
                height: 1.9rem;
                > img {
                    width: 2.04rem;
                    height: 1.26rem;
                    margin-left: 0.4rem;
                    margin-top: 0.32rem;
                }
            }

            .integral-item-liRight {
                width: 5.26rem;
                margin-left: 0.2rem;
                margin-top: 0.32rem;
                .integral-liRight-top {
                    display: flex;
                    justify-content: space-between;
                    > span:first-child {
                        font-size: 0.26rem;
                        font-weight: 600;
                        color: #000000;
                    }

                    > span:last-child {
                        font-size: 0.24rem;
                        font-weight: 400;
                        color: #999999;
                        margin-right: 0.4rem;
                    }
                }

                .integral-liRight-center {
                    font-size: 0.2rem;
                    font-weight: 400;
                    color: #999999;
                    margin-top: 0.2rem;
                }

                .integral-liRight-bottom {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 0.2rem;
                    .integral-liRight-bottomL  {
                        background: #F9F9F9;
                        border-radius: 0.04rem;
                        font-size: 0.2rem;
                        font-weight: 400;
                        color: #999999;   
                        padding: 0.08rem;
                    }

                    .integral-liRight-bottomR {
                        display: flex;
                        > div:first-child {
                            font-size: 0.28rem;
                            font-weight: 500;
                            color: #FF503F;
                            margin-right: 0.02rem;
                        }
                        
                        > div:last-child {
                            font-size: 0.2rem;
                            font-weight: 400;
                            color: #FF503F;
                            margin-right: 0.4rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
